/* eslint-disable no-shadow */
import React, { useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import {
  addEmailAddress,
  addFullName,
  addPhoneNumber,
} from '../../stores/actions';

import Infobox from '../../components/Infobox';
import iconBackArrow from '../../assets/icon-back.svg';

const validationSchema = Yup.object({
  fullName: Yup.string().required('Por favor digite seu nome completo'),
  emailAddress: Yup.string()
    .email('Por favor digite um e-mail válido')
    .required('Por digite um e-mail'),
  phoneNumber: Yup.string().required('Por favor digite um telefone'),
});

function Step04() {
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState('default');
  const keyboard = useRef();

  const [showKeyboard, setShowKeyboard] = useState(false);

  const [layoutName, setLayoutName] = useState('default');

  const dispatch = useDispatch();

  const handleAddEmailAddress = useCallback(
    (values) => {
      dispatch(addEmailAddress(values));
    },
    [dispatch]
  );

  const handleAddFullName = useCallback(
    (values) => {
      dispatch(addFullName(values));
    },
    [dispatch]
  );

  const handleAddPhoneNumber = useCallback(
    (values) => {
      dispatch(addPhoneNumber(values));
    },
    [dispatch]
  );

  const location = {
    pathname: `/simulador/resultado`,
  };

  const history = useHistory();

  function handleClick() {
    history.push('/simulador/passo-03');
  }

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: {
      fullName: '',
      emailAddress: '',
      phoneNumber: '',
    },
    validationSchema,
    onSubmit(values) {
      handleAddEmailAddress(values.emailAddress);
      handleAddFullName(values.fullName);
      handleAddPhoneNumber(values.phoneNumber);
      console.log(values);
      history.push(location);
    },
  });

  const onChangeAll = (inputs) => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs });
    setValues({ ...inputs });
  };

  const onKeyPress = (button) => {
    if (button === '{ent}') handleSubmit();
    if (button === '{numbers}') setLayoutName('numbers');
    if (button === '{abc}') setLayoutName('default');
    if (button === '{shift}') setLayoutName('shift');
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    handleChange(event);
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });
    setValues({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (item) => {
    return inputs[item] || '';
  };

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <main className="main__simulator">
          <header className="header">
            <div className="container">
              <div className="header--display">
                <span className="pagination">4/4</span>
                <button type="button" onClick={handleClick}>
                  <img src={iconBackArrow} alt="Voltar" />
                </button>
              </div>
            </div>
          </header>
          <div className="container">
            <div className="form__header">
              <span>4</span>
              <p>E quais são os seus dados para contato?</p>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">
              Seu nome:
            </label>
            <input
              autoComplete={false}
              placeholder="Digite seu nome"
              className="form-input"
              type="text"
              name="fullName"
              id="fullName"
              value={getInputValue('fullName')}
              onChange={onChangeInput}
              onFocus={() => {
                setInputName('fullName');
                setShowKeyboard(true);
              }}
            />
            {errors.fullName ? (
              <p className="form-error">{errors.fullName}</p>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="emailAddress" className="form-label">
              Seu e-mail:
            </label>
            <input
              placeholder="Digite o seu e-mail"
              className="form-input"
              type="text"
              name="emailAddress"
              id="emailAddress"
              value={getInputValue('emailAddress')}
              onChange={onChangeInput}
              onFocus={() => {
                setInputName('emailAddress');
                setShowKeyboard(true);
              }}
            />
            {errors.emailAddress ? (
              <p className="form-error">{errors.emailAddress}</p>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="emailAddress" className="form-label">
              Seu Telefone:
            </label>
            <NumberFormat
              format="(##) #####-####"
              mask=""
              placeholder="Digite o telefone"
              className="form-input"
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={getInputValue('phoneNumber')}
              onChange={onChangeInput}
              onFocus={() => {
                setInputName('phoneNumber');
                setShowKeyboard(true);
              }}
            />
            {errors.phoneNumber ? (
              <p className="form-error">{errors.phoneNumber}</p>
            ) : null}
          </div>
          <div className="form-group">
            <label className="form-label-radio small">
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                value="privacy"
                onChange={handleChange}
                values={values.privacy}
                defaultChecked
              />
              <span className="form-custom-checkbox">&#9632;</span>
              <p>
                Autorizo que meus dados sejam coletados para eventual contato
                com ofertas e novidades. Leia a
                <a href="/politica-de-privacidade"> Política de Privacidade</a>
              </p>
            </label>
          </div>

          <Infobox bg={false} height={false} />

          {isValid && dirty && (
            <div className="button-box">
              <button className="button" type="submit">
                {isSubmitting ? 'Aguarde...' : 'Ver Reultado'}
              </button>
            </div>
          )}
        </main>
      </form>
      {showKeyboard && (
        <Keyboard
          // eslint-disable-next-line no-return-assign
          keyboardRef={(r) => (keyboard.current = r)}
          theme="hg-theme-default myTheme1"
          inputName={inputName}
          layoutName={layoutName}
          onChangeAll={onChangeAll}
          onKeyPress={onKeyPress}
          mergeDisplay
          layout={{
            default: [
              '.com.br .com . @ - _',
              'q w e r t y u i o p',
              'a s d f g h j k l',
              '{shift} z x c v b n m {backspace}',
              '{numbers} {space} {ent}',
            ],
            shift: [
              'Q W E R T Y U I O P',
              'A S D F G H J K L',
              '{shift} Z X C V B N M {backspace}',
              '{numbers} {space} {ent}',
            ],
            numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}'],
          }}
          display={{
            '{numbers}': '123',
            '{ent}': 'Entrar',
            '{escape}': 'esc ⎋',
            '{tab}': 'tab ⇥',
            '{backspace}': '⌫',
            '{capslock}': 'caps lock ⇪',
            '{shift}': '⇧',
            '{controlleft}': 'ctrl ⌃',
            '{controlright}': 'ctrl ⌃',
            '{altleft}': 'alt ⌥',
            '{altright}': 'alt ⌥',
            '{metaleft}': 'cmd ⌘',
            '{metaright}': 'cmd ⌘',
            '{abc}': 'ABC',
          }}
        />
      )}
    </>
  );
}

export default Step04;

/* eslint-disable no-shadow */
import React, { useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import { addFgtsAmount } from '../../stores/actions';
import Infobox from '../../components/Infobox';

import iconBackArrow from '../../assets/icon-back.svg';

const validationSchema = Yup.object({
  fgtsAmount: Yup.string().required('Por favor informe o valor do FGTS'),
});

function Step03() {
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState('default');
  const keyboard = useRef();
  const dispatch = useDispatch();

  const [activeInputFGTS, setActiveInputFGTS] = useState(true);
  const [fgtsAmount, setFgtsAmount] = useState('0.00');

  const [showKeyboard, setShowKeyboard] = useState(false);

  const layoutName = 'num';

  const history = useHistory();

  function handleClick() {
    history.push('/simulador/passo-02');
  }

  const handleAddfgtsAmount = useCallback(
    (values) => {
      dispatch(addFgtsAmount(values));
    },
    [dispatch]
  );

  const location = {
    pathname: `/simulador/passo-04`,
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: {
      fgtsAmount: '0.00',
    },
    validationSchema,
    onSubmit() {
      handleAddfgtsAmount(Number(fgtsAmount));
      history.push(location);
    },
  });

  const onChangeAll = (inputs) => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs });
    setValues({ ...inputs });
  };

  const onKeyPress = (button) => {
    if (button === '{enter}') handleSubmit();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    handleChange(event);
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });
    setValues({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (item) => {
    return inputs[item] || '';
  };

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <main className="main__simulator">
          <header className="header">
            <div className="container">
              <div className="header--display">
                <span className="pagination">3/4</span>
                <button type="button" onClick={handleClick}>
                  <img src={iconBackArrow} alt="Voltar" />
                </button>
              </div>
            </div>
          </header>

          <div className="container">
            <div className="form__header">
              <span>3</span>
              <p>Você possui FGTS?</p>
            </div>
          </div>

          <div className="form-group">
            <p className="form-label">
              Trabalhou mais 3 anos sob regime do FGTS?
            </p>

            <div className="d-flex">
              <label className="form-label-radio">
                <input
                  type="radio"
                  name="fgts"
                  id="income-one"
                  value="sim"
                  onChange={() => setActiveInputFGTS(true)}
                  values={values.fgts}
                  defaultChecked
                />
                <span className="form-custom-radio">.</span>
                Sim
              </label>
              <label className="form-label-radio">
                <input
                  type="radio"
                  name="fgts"
                  id="income-one"
                  value="não"
                  onChange={() => setActiveInputFGTS(false)}
                  values={values.fgts}
                />
                <span className="form-custom-radio">.</span>
                Não
              </label>
            </div>
            <br />
            {activeInputFGTS && (
              <div>
                <label htmlFor="fgtsAmount" className="form-label">
                  Valor do FGTS:
                </label>
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  suffix=",00"
                  placeholder="Digite o valor"
                  className="form-input"
                  type="text"
                  name="fgtsAmount"
                  id="fgtsAmount"
                  onValueChange={(values) => {
                    const { value } = values;
                    setFgtsAmount(value);
                  }}
                  onFocus={() => {
                    setInputName('fgtsAmount');
                    setShowKeyboard(true);
                  }}
                  onChange={onChangeInput}
                  value={getInputValue('fgtsAmount')}
                />
                {errors.fgtsAmount ? (
                  <p className="form-error">{errors.fgtsAmount}</p>
                ) : null}
              </div>
            )}
          </div>

          <Infobox
            title="FGTS"
            description="O FGTS muito importante responder se o FGTS será usado ou não, pois isso reduz a taxa
          de juros."
          />

          {((isValid && dirty) || !activeInputFGTS) && (
            <div className="button-box">
              <button className="button" type="submit">
                {isSubmitting ? 'Aguarde...' : 'Continuar'}
              </button>
            </div>
          )}
        </main>
      </form>
      {activeInputFGTS && showKeyboard && (
        <Keyboard
          // eslint-disable-next-line no-return-assign
          keyboardRef={(r) => (keyboard.current = r)}
          theme="hg-theme-default myTheme1"
          layoutName={layoutName}
          inputName={inputName}
          onKeyPress={onKeyPress}
          onChangeAll={onChangeAll}
          layout={{
            num: ['1 2 3', '4 5 6', '7 8 9', ' 0 ', '{bksp} {enter}'],
          }}
          display={{
            '{bksp}': 'Retroceder',
            '{enter}': 'Entrar',
          }}
        />
      )}
    </>
  );
}

export default Step03;

import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const GoogleMaps = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={12} defaultCenter={props.center}>
      {props.markers.map((marker) => (
        <Marker
          key={marker.id}
          position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
          icon={{ url: marker.icon }}
        />
      ))}
    </GoogleMap>
  ))
);

export default GoogleMaps;

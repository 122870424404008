import React from 'react';
import { Link } from 'react-router-dom';

const CardEnterprise = ({ data }) => {
  return (
    <>
      {data.enterprises.map(
        ({
          name,
          region,
          neighborhood,
          city,
          phaseName,
          priceFirstLine,
          priceSecondLine,
          dormsText,
          searchListingImageUrl,
        }) => (
          <Link
            to={`/imoveis/${data.slug}`}
            className={`card__enterprise ${region}`}
          >
            <img
              className="card__enterprise--image"
              src={searchListingImageUrl}
              alt={name}
            />
            <div className="card__enterprise_content">
              <p className="city">{city}</p>
              <p className="neighborhood">{neighborhood}</p>
              <h1 className="name">{name}</h1>
              <p className="group">
                <span className="dorms">{dormsText}</span>
                <span className="phase">{phaseName}</span>
              </p>
              <p className="price">
                <span className="price-first-line">{priceFirstLine}</span>
                <span className="price-second-line">{priceSecondLine}</span>
              </p>
            </div>
          </Link>
        )
      )}
    </>
  );
};

export default CardEnterprise;

import React from 'react';

import imgPriscila from '../assets/pri_calculadora.png';
import iconInfo from '../assets/icon-info.svg';

const Infobox = ({ title, description, bg = true, height = true }) => (
  <>
    <div className={`infobox ${!height && 'auto'}`}>
      {title && description && (
        <div className="infobox--content">
          <img src={iconInfo} alt="" />
          <p>
            <strong>{title}</strong>
            <span>{description}</span>
          </p>
        </div>
      )}
      {bg && <img className="infobox--image" src={imgPriscila} alt="" />}
    </div>
  </>
);

export default Infobox;

import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
  headers: {
    'X-API-TOKEN': `${process.env.REACT_APP_TOKEN_API}`,
    'Content-Type': 'multipart/form-data',
  },
});

export default api;

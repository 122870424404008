export function addRegion(region) {
  return {
    type: 'ADD_REGION',
    payload: {
      region,
    },
  };
}

export function addNeighborhood(neighborhood) {
  return {
    type: 'ADD_NEIGHBORHOOD',
    payload: {
      neighborhood,
    },
  };
}

export function addFamilyTotalIncome(familyTotalIncome) {
  return {
    type: 'ADD_FAMILY_TOTAL_AMOUNT',
    payload: {
      familyTotalIncome,
    },
  };
}

export function addFgtsAmount(fgtsAmount) {
  return {
    type: 'ADD_FGTS_AMOUNT',
    payload: {
      fgtsAmount,
    },
  };
}

export function addEmailAddress(emailAddress) {
  return {
    type: 'ADD_EMAIL_ADDRESS',
    payload: {
      emailAddress,
    },
  };
}

export function addPhoneNumber(phoneNumber) {
  return {
    type: 'ADD_PHONE_NUMBER',
    payload: {
      phoneNumber,
    },
  };
}

export function addFullName(fullName) {
  return {
    type: 'ADD_FULLNAME',
    payload: {
      fullName,
    },
  };
}

export function addEnterprises(enterprisesGroups) {
  return {
    type: 'ADD_ENTERPRISES',
    payload: {
      enterprisesGroups,
    },
  };
}

import React from 'react';

import { Link } from 'react-router-dom';

import logo from '../../assets/planoeplano.svg';
import imgPriscila from '../../assets/priscila.png';
import iconRenda from '../../assets/icon-renda.svg';
import iconConditions from '../../assets/icon-conditions.svg';
import iconDoc from '../../assets/icon-doc.svg';
import imgMcmv from '../../assets/mcmv.png';

function Home() {
  return (
    <>
      <div className="container">
        <header className="header">
          <img src={logo} alt="" />
        </header>
        <section className="content__top">
          <img className="content--image" src={imgPriscila} alt="" />
          <h1 className="title">Procurando um apê que cabe no seu bolso?</h1>
        </section>
      </div>
      <section className="content__bottom">
        <div className="container">
          <h2 className="subtitle">
            Com a Plano<span>&</span>Plano é possível sim comprar o seu apê.
            Faça agora mesmo uma simulação e descubra como!
          </h2>
          <Link to="/simulador/passo-01" className="button">
            Começar
          </Link>

          <div className="content__info">
            <div className="content__info--display">
              <div>
                <div className="card">
                  <img src={iconRenda} alt="" />
                  <p>
                    Renda a partir de
                    <br />
                    <span>
                      R$ 2.100<sup>5</sup>
                    </span>
                  </p>
                </div>

                <div className="card">
                  <img src={iconDoc} alt="" />
                  <p>
                    Documentação
                    <br />
                    <span>
                      Grátis<sup>2</sup>
                    </span>
                  </p>
                </div>

                <div className="card">
                  <img src={iconConditions} alt="" />
                  <p>
                    Entrada em até
                    <br />
                    <span>
                      30X<sup>**</sup>
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <img src={imgMcmv} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

/* eslint-disable no-shadow */
import React, { useRef, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import NumberFormat from 'react-number-format';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import { addFamilyTotalIncome } from '../../stores/actions';

import Infobox from '../../components/Infobox';
import iconBackArrow from '../../assets/icon-back.svg';
import imgIconUmaPessoa from '../../assets/icon-uma-pessoa.svg';
import imgIconDuasPessoas from '../../assets/icon-duas-pessoas.svg';
import imgIconTresPessoas from '../../assets/icon-tres-pessoas.svg';

const validationSchema = Yup.object({
  firstIncome: Yup.string().required('Por favor informe a sua renda'),
  secondIncome: Yup.string().required('Por favor informe a sua renda'),
  thirdIncome: Yup.string().required('Por favor informe a sua renda'),
});

function Step02Second() {
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState('default');
  const keyboard = useRef();

  const dispatch = useDispatch();

  const [firstIncome, setFirstIncome] = useState('');
  const [secondIncome, setSecondIncome] = useState('');
  const [thirdIncome, setThirdIncome] = useState('');

  const [showKeyboard, setShowKeyboard] = useState(false);

  const layoutName = 'num';

  const history = useHistory();

  function handleClick() {
    history.push('/simulador/passo-02');
  }

  const { step } = useParams();

  const handleAddfamilyTotalIncome = useCallback(
    (values) => {
      dispatch(addFamilyTotalIncome(values));
    },
    [dispatch]
  );

  const location = {
    pathname: `/simulador/passo-03`,
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    errors,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: {
      firstIncome: '',
      secondIncome: '',
      thirdIncome: '',
    },
    validationSchema,
    onSubmit() {
      const familyTotalIncome =
        Number(firstIncome) + Number(secondIncome) + Number(thirdIncome);
      handleAddfamilyTotalIncome(familyTotalIncome);
      history.push(location);
    },
  });

  const onChangeAll = (inputs) => {
    setInputs({ ...inputs });
    setValues({ ...inputs });
  };

  const onKeyPress = (button) => {
    if (button === '{enter}') handleSubmit();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    handleChange(event);
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });
    setValues({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = (item) => {
    return inputs[item] || '';
  };

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <main className="main__simulator">
          <header className="header">
            <div className="container">
              <div className="header--display">
                <span className="pagination">2/4</span>
                <button type="button" onClick={handleClick}>
                  <img src={iconBackArrow} alt="Voltar" />
                </button>
              </div>
            </div>
          </header>
          <div className="container">
            <div className="form__header">
              <span>2</span>
              <p>Quantas pessoas vão combinar a renda com você?</p>
            </div>
          </div>
          <div className="card__container">
            {step === 'passo-02-uma-renda' && (
              <div className="card-income">
                <img src={imgIconUmaPessoa} alt="Só a minha renda" />
                <p>Só a minha renda</p>
              </div>
            )}
            {step === 'passo-02-duas-rendas' && (
              <div className="card-income">
                <img src={imgIconDuasPessoas} alt="Eu e mais 1 pessoa" />
                <p>Eu e mais 1 pessoa</p>
              </div>
            )}
            {step === 'passo-02-tres-rendas' && (
              <div className="card-income">
                <img src={imgIconTresPessoas} alt="Eu e mais 2 pessoas" />
                <p>Eu e mais 2 pessoas</p>
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="firsIncome" className="form-label">
              Sua Renda
            </label>
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              prefix="R$ "
              suffix=",00"
              placeholder="Preencha a renda"
              className="form-input"
              type="text"
              name="firstIncome"
              id="firstIncome"
              onValueChange={(values) => {
                const { value } = values;
                setFirstIncome(value);
              }}
              value={getInputValue('firstIncome')}
              onChange={onChangeInput}
              onFocus={() => {
                setInputName('firstIncome');
                setShowKeyboard(true);
              }}
            />
            {errors.firstIncome ? (
              <p className="form-error">{errors.firstIncome}</p>
            ) : null}
          </div>

          {(step === 'passo-02-duas-rendas' ||
            step === 'passo-02-tres-rendas') && (
            <div className="form-group">
              <label htmlFor="secondIncome" className="form-label">
                Renda da segunda pessoa
              </label>
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                suffix=",00"
                placeholder="Preencha a renda"
                className="form-input"
                type="text"
                name="secondIncome"
                id="secondIncome"
                onValueChange={(values) => {
                  const { value } = values;
                  setSecondIncome(value);
                }}
                value={getInputValue('secondIncome')}
                onFocus={() => {
                  setInputName('secondIncome');
                  setShowKeyboard(true);
                }}
                onChange={onChangeInput}
              />
              {errors.secondIncome ? (
                <p className="form-error">{errors.secondIncome}</p>
              ) : null}
            </div>
          )}
          {step === 'passo-02-tres-rendas' && (
            <div className="form-group">
              <label htmlFor="thirdIncome" className="form-label">
                Renda da terceira pessoa
              </label>
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                suffix=",00"
                placeholder="Preencha a renda"
                className="form-input"
                type="text"
                name="thirdIncome"
                id="thirdIncome"
                onValueChange={(values) => {
                  const { value } = values;
                  setThirdIncome(value);
                }}
                value={getInputValue('thirdIncome')}
                onChange={onChangeInput}
                onFocus={() => {
                  setInputName('thirdIncome');
                  setShowKeyboard(true);
                }}
              />
              {errors.thirdIncome ? (
                <p className="form-error">{errors.thirdIncome}</p>
              ) : null}
            </div>
          )}

          <Infobox
            title="Composição de renda:"
            description="Pode ser qualquer familiar, namorado(a) ou até um amigo."
          />
          {isValid && dirty && (
            <div className="button-box">
              <button className="button" type="submit">
                {isSubmitting ? 'Aguarde...' : 'Continuar'}
              </button>
            </div>
          )}
        </main>
      </form>
      {showKeyboard && (
        <Keyboard
          // eslint-disable-next-line no-return-assign
          keyboardRef={(r) => (keyboard.current = r)}
          theme="hg-theme-default myTheme1"
          inputName={inputName}
          layoutName={layoutName}
          onChangeAll={onChangeAll}
          onKeyPress={onKeyPress}
          layout={{
            num: ['1 2 3', '4 5 6', '7 8 9', ' 0 ', '{bksp} {enter}'],
          }}
          display={{
            '{bksp}': '⌫',
            '{enter}': 'Entrar',
          }}
        />
      )}
    </>
  );
}

export default Step02Second;

import React from 'react';
import { useParams } from 'react-router-dom';

import Step01 from './step-01';
import Step02First from './step-02-1';
import Step02Second from './step-02-2';
import Step03 from './step-03';
import Step04 from './step-04';
import Result from './result';

const Simulator = () => {
  const { step } = useParams();

  return (
    <>
      {step === 'passo-01' && <Step01 />}
      {step === 'passo-02' && <Step02First />}
      {step === 'passo-02-uma-renda' && <Step02Second />}
      {step === 'passo-02-duas-rendas' && <Step02Second />}
      {step === 'passo-02-tres-rendas' && <Step02Second />}
      {step === 'passo-03' && <Step03 />}
      {step === 'passo-04' && <Step04 />}
      {step === 'resultado' && <Result />}
    </>
  );
};

export default Simulator;

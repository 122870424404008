import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import api from '../../services/api';

import GoogleMaps from '../../components/GoogleMaps';

import iconBackArrow from '../../assets/icon-back.svg';

import imgSeal from '../../assets/mcmv.svg';
import iconNext from '../../assets/next.svg';
import iconPrev from '../../assets/prev.svg';
import markerEnterprise from '../../assets/enterprise-marker.svg';
import markerStore from '../../assets/store-marker.svg';
import iconWhats from '../../assets/whatsapp-brands.svg';
import iconBullet from '../../assets/bullet-title.svg';

import iconRegionEast from '../../assets/arrow-region-east.svg';
import iconRegionSouth from '../../assets/arrow-region-south.svg';
import iconRegionNorth from '../../assets/arrow-region-north.svg';
import iconRegionWest from '../../assets/arrow-region-west.svg';
import iconRegionDowntown from '../../assets/arrow-region-downtown.svg';
import iconRegionCountryside from '../../assets/arrow-region-countryside.svg';
import iconRegionOutside from '../../assets/arrow-region-outside.svg';

const EnterpriseSingle = () => {
  const history = useHistory();

  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({});

  function handleClick() {
    history.push('/imoveis');
  }

  const { slug } = useParams();
  const [enterprisesGroups, setEnterprisesGroups] = useState([]);

  const arrowStyles = {
    position: 'absolute',
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
  };

  useEffect(() => {
    api.get(`/empreendimentos/${slug}`).then((response) => {
      setEnterprisesGroups(response.data.data.enterprisesGroups);
    });
  }, [slug]);

  useEffect(() => {
    enterprisesGroups.map(
      ({ location, salesCenterLocation, secondarySalesCenterLocation }) => {
        if (location || salesCenterLocation || secondarySalesCenterLocation) {
          setMarkers((marker) => [
            ...marker,
            {
              id: 1,
              icon: `${markerEnterprise}`,
              lat: location.lat,
              lng: location.lng,
            },
            {
              id: 2,
              icon: `${markerStore}`,
              lat: salesCenterLocation.lat,
              lng: salesCenterLocation.lng,
            },
            {
              id: 3,
              icon: `${markerStore}`,
              lat: secondarySalesCenterLocation.lat,
              lng: secondarySalesCenterLocation.lng,
            },
          ]);
        }
        return true;
      }
    );
  }, [enterprisesGroups]);

  useEffect(() => {
    enterprisesGroups.map(({ location }) => {
      if (location) {
        setCenter({
          lat: Number(location.lat),
          lng: Number(location.lng),
        });
      }
      return true;
    });
  }, [enterprisesGroups]);

  return (
    <>
      <header className="header mb-0">
        <div className="container">
          <div className="header--display">
            <span className="pagination" />
            <button type="button" onClick={handleClick}>
              <img src={iconBackArrow} alt="Voltar" />
            </button>
          </div>
        </div>
      </header>

      {enterprisesGroups.map(({ enterprises }) =>
        enterprises.map(
          ({
            id,
            regionName,
            name,
            city,
            phase,
            dormsText,
            region,
            mainPhotos,
            video,
            location,
            salesCenterLocation,
            secondarySalesCenterLocation,
          }) => (
            <main className={`single ${region}`} key={id}>
              <p className="city">
                {region === 'east' && (
                  <img src={iconRegionEast} alt={region} width="36" />
                )}
                {region === 'south' && (
                  <img src={iconRegionSouth} alt={region} width="36" />
                )}
                {region === 'north' && (
                  <img src={iconRegionNorth} alt={region} width="36" />
                )}
                {region === 'west' && (
                  <img src={iconRegionWest} alt={region} width="36" />
                )}
                {region === 'countryside' && (
                  <img src={iconRegionCountryside} alt={region} width="36" />
                )}
                {region === 'outside' && (
                  <img src={iconRegionOutside} alt={region} width="36" />
                )}
                {region === 'downtown' && (
                  <img src={iconRegionDowntown} alt={region} width="36" />
                )}
                <span>{regionName}</span> -<span>{city}</span>
              </p>
              <h1 className="name">{name}</h1>
              <div className="details">
                <div>
                  <img width="200" src={imgSeal} alt="" />
                </div>
                <div>
                  {dormsText && <p className="dorms">{dormsText}</p>}
                  {phase && <p className="phase">{phase}</p>}
                </div>
              </div>
              <div className="section photos">
                <h2 className="section--title">
                  <img src={iconBullet} alt="" width="14" />
                  Fotos
                </h2>
                <h3 className="section--subtitle">Encante-se pelo apê.</h3>
                <Carousel
                  autoPlay
                  showThumbs={false}
                  showIndicators={false}
                  interval={3000}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 30 }}
                      >
                        <img src={iconPrev} alt={label} />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 30 }}
                      >
                        <img src={iconNext} alt={label} />
                      </button>
                    )
                  }
                >
                  {mainPhotos &&
                    mainPhotos.map(({ url, description }) => (
                      <div key={id}>
                        <img className="photo" src={url} alt="" />
                        <p className="description">{description}</p>
                      </div>
                    ))}
                </Carousel>
              </div>
              {video !== '' && (
                <div className="section videos">
                  <h2 className="section--title">
                    <img src={iconBullet} alt="" width="14" />
                    Video
                  </h2>
                  <h3 className="section--subtitle">
                    Veja o video do decorado
                  </h3>
                </div>
              )}

              <div className="section videos">
                <h2 className="section--title">
                  <img src={iconBullet} alt="" width="14" />
                  Localização
                </h2>
                <h3 className="section--subtitle">
                  Você morando numa localização mais que privilegiada.
                </h3>
              </div>
              {location.found === true && (
                <div className="street">
                  <img
                    src={markerEnterprise}
                    alt="Endereço do empreendimento"
                    width="74"
                    className="street--icon"
                  />
                  <p className="street--text">
                    <strong>Endereço do empreendimento:</strong>
                    <br />
                    {location.street}
                  </p>
                </div>
              )}

              {salesCenterLocation.found === true && (
                <div className="street">
                  <img
                    src={markerStore}
                    alt="Endereço do empreendimento"
                    width="74"
                    className="street--icon"
                  />
                  <p className="street--text">
                    <strong>Central de vendas:</strong>
                    <br />
                    {salesCenterLocation.street}
                  </p>
                </div>
              )}

              {secondarySalesCenterLocation.found === true && (
                <div className="street">
                  <img
                    src={markerStore}
                    alt="Endereço do empreendimento"
                    width="74"
                    className="street--icon"
                  />
                  <p className="street--text">
                    <strong>Central de vendas:</strong>
                    <br />
                    {secondarySalesCenterLocation.street}
                  </p>
                </div>
              )}

              <GoogleMaps
                center={center}
                markers={markers}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `600px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />

              <button
                className="button button--block button--whatsapp"
                type="button"
                onClick={handleClick}
              >
                <img src={iconWhats} alt="" width="48" />
                Quero enviar para meu whats
              </button>

              <button
                className="button button--block"
                type="button"
                onClick={handleClick}
              >
                Ver outros apês
              </button>
            </main>
          )
        )
      )}
    </>
  );
};

export default EnterpriseSingle;

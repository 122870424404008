import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';

import api from '../../services/api';

import { addEnterprises } from '../../stores/actions';

import iconBackArrow from '../../assets/icon-back.svg';
import iconRendaGreen from '../../assets/icon-renda-green.svg';
import iconConditionsGreen from '../../assets/icon-conditions-green.svg';
import iconCheck from '../../assets/icon-check.svg';

function Result() {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);

  const formdata = useSelector((state) => state.formdata);

  const history = useHistory();

  const data = new FormData();

  formdata.map(({ key, value }) => data.append(key, value));

  function handleClick() {
    history.push('/simulador/passo-04');
  }

  const handleAddEnterprises = useCallback(
    (value) => {
      dispatch(addEnterprises(value));
    },
    [dispatch]
  );

  useEffect(() => {
    api
      .post('/simulacao', data)
      .then((response) => {
        setResult(response.data.data);
        handleAddEnterprises(response.data.data.enterprisesGroups);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  return (
    <>
      <main className="main__simulator">
        <header className="header">
          <div className="container">
            <div className="header--display">
              <span className="pagination" />
              <button type="button" onClick={handleClick}>
                <img src={iconBackArrow} alt="Voltar" />
              </button>
            </div>
          </div>
        </header>
        <div className="result">
          <p className="result--title">
            Resultado da sua <br />
            simulação:
          </p>
          <p className="result--subtitle">
            Você pode realizar seu sonho e comprar
            <br />
            um apê de até:
          </p>
          <p className="result-potential-amount">{result.potentialAmount}</p>

          <div className="d-flex align-center justify-between">
            <div className="card">
              <img src={iconRendaGreen} alt="" />
              <p>
                Renda a partir de
                <br />
                <span className="result-subsidy-total-amount">
                  {result.subsidyTotalAmount}
                </span>
              </p>
            </div>
            <div className="card">
              <img src={iconConditionsGreen} alt="" />
              <p>
                Parcelas de
                <br />
                <span className="result-parcel-amount">
                  {result.parcelAmount}
                </span>
              </p>
            </div>
          </div>

          <div className="result-checks">
            <p>
              <img src={iconCheck} alt="" />
              <span>Até 30 anos para pagar</span>
            </p>
            <p>
              <img src={iconCheck} alt="" />
              <span>Seguro desemprego</span>
            </p>
            <p>
              <img src={iconCheck} alt="" />
              <span>Qualidade Plano&Plano</span>
            </p>
            <p>
              <img src={iconCheck} alt="" />
              <span>Financiamento Caixa</span>
            </p>
          </div>

          <div className="result-legal-text">
            <p>
              - Renda familiar informada de:
              <span className="result-family-total-income">
                {result.familyTotalIncome}
              </span>
              - FGTS Informado:
              <span className="result-fgts-total-amount">
                {result.fgtsTotalAmount}
              </span>
              .
            </p>
            <p>
              - Sujeito a aprovação de crédito conforme políticias da
              Plano&Plano e agentes financeiros.
            </p>
            <p>
              - Os resultados são apenas uma simulação e não uma proposta de
              financimento.
            </p>
            <p>
              - Simulação realizada com base nas condições vigentes da Caixa,
              sujeita a alteração.
            </p>
            <p>
              * O imóvel não pode ser 100% financiado. Os bancos trabalham com
              financiamento de 80% do valor total do imóvel.
            </p>
          </div>

          <Link to="/imoveis" className="button">
            Veja os imóveis que você <br />
            pode comprar
          </Link>
        </div>
      </main>
    </>
  );
}

export default Result;

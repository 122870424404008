import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { addRegion, addNeighborhood } from '../../stores/actions';

import api from '../../services/api';
import Infobox from '../../components/Infobox';

import iconBackArrow from '../../assets/icon-back.svg';

const validationSchema = Yup.object({
  region: Yup.string().required('Por favor, escolha uma região'),
  neighborhood: Yup.string().required('Por favor escolha um bairro'),
});

function Step01() {
  const dispatch = useDispatch();

  const [regions, setRegions] = useState([]);
  const [neighborhoods, setNeighborhood] = useState([]);
  const [regionSlug, setRegionSlug] = useState();
  const [citySlug, setCitySlug] = useState();
  const [regionId, setRegionId] = useState();
  const [neighborhoodId, setNeighborhoodId] = useState();

  const history = useHistory();
  const location = {
    pathname: '/simulador/passo-02',
  };
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: {
      region: '',
      neighborhood: '',
    },
    validationSchema,
    onSubmit(values) {
      handleAddRegion(regionId);
      handleAddNeighborhood(neighborhoodId);
      // console.log(values);
      history.push(location);
    },
  });
  //console.log(neighborhoodId);

  const handleChangeRegions = (e) => {
    const regionSelected = e.currentTarget.value;
    handleChange(e);
    setRegionSlug(regionSelected);
    regions.map(({ regionSlug, citySlug, id }) => {
      if (regionSlug === regionSelected) {
        setCitySlug(citySlug);
        setRegionId(id);
      }
      return true;
    });
  };

  const handleChangeNeighborhoods = (e) => {
    const neighborhoodsSelected = e.currentTarget.value;
    handleChange(e);
    neighborhoods.map(({ slug, id }) => {
      if (slug === neighborhoodsSelected) {
        setNeighborhoodId(id);
      }
      return true;
    });
  };

  const handleAddRegion = useCallback(
    (values) => {
      dispatch(addRegion(values));
    },
    [dispatch]
  );

  const handleAddNeighborhood = useCallback(
    (values) => {
      dispatch(addNeighborhood(values));
    },
    [dispatch]
  );

  useEffect(() => {
    api.get('/atendimento/filtros/regioes').then((response) => {
      setRegions(response.data.data);
    });
  }, []);

  useEffect(() => {
    api
      .get(`/atendimento/filtros/bairros/${regionSlug}/${citySlug}`)
      .then((response) => {
        setNeighborhood(response.data.data);
      });
  }, [regions, regionSlug, citySlug]);

  function handleClick() {
    history.push('/');
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <main className="main__simulator">
          <header className="header">
            <div className="container">
              <div className="header--display">
                <span className="pagination">1/4</span>
                <button type="button" onClick={handleClick}>
                  <img src={iconBackArrow} alt="Voltar" />
                </button>
              </div>
            </div>
          </header>

          <div className="container">
            <div className="form__header">
              <span>1</span>
              <p>Qual a região e bairro que você deseja morar?</p>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="region" className="form-label">
              Região de Interesse
            </label>
            <select
              id="region"
              className="form-select"
              name="region"
              onChange={(e) => handleChangeRegions(e)}
              values={values.region}
            >
              <option value="">Selecione uma opção</option>
              {regions.map(({ name, id, regionSlug }) => (
                <option key={id} value={regionSlug}>
                  {name}
                </option>
              ))}
            </select>
            {errors.region ? (
              <p className="form-error">{errors.region}</p>
            ) : null}
          </div>
          {neighborhoods.length > 1 && (
            <div className="form-group">
              <label htmlFor="neighborhood" className="form-label">
                Bairro de interesse
              </label>
              <select
                id="neighborhood"
                className="form-select"
                name="neighborhood"
                onChange={(e) => handleChangeNeighborhoods(e)}
                values={values.neighborhood}
              >
                <option value="">Selecione uma opção</option>
                {neighborhoods.map(({ name, id, slug }) => (
                  <option key={id} value={slug}>
                    {name}
                  </option>
                ))}
              </select>
              {errors.region ? (
                <p className="form-error">{errors.region}</p>
              ) : null}
            </div>
          )}

          <Infobox />

          {isValid && dirty && (
            <div className="button-box">
              <button className="button" type="submit">
                {isSubmitting ? 'Aguarde...' : 'Continuar'}
              </button>
            </div>
          )}
        </main>
      </form>
    </>
  );
}

export default Step01;

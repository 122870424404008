import { createStore } from 'redux';
import storeSynchronize, { defineState } from 'redux-localstore';

const INITIAL_STATE = {
  formdata: [
    {
      key: 'familyTotalIncome',
      value: '',
    },
    {
      key: 'fgtsAmount',
      value: '',
    },
    {
      key: 'fullName',
      value: '',
    },
    {
      key: 'emailAddress',
      value: '',
    },
    {
      key: 'phoneNumber',
      value: '',
    },
    {
      key: 'regionId',
      value: '',
    },
    {
      key: 'neighborhoodId',
      value: '',
    },
  ],
  enterprisesGroups: [],
};

const initialState = defineState(INITIAL_STATE)('content');

const content = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_REGION': {
      const { region } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'regionId'
            ? {
                ...item,
                value: region,
              }
            : item
        ),
      };
    }

    case 'ADD_NEIGHBORHOOD': {
      const { neighborhood } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'neighborhoodId'
            ? {
                ...item,
                value: neighborhood,
              }
            : item
        ),
      };
    }

    case 'ADD_FAMILY_TOTAL_AMOUNT': {
      const { familyTotalIncome } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'familyTotalIncome'
            ? {
                ...item,
                value: familyTotalIncome,
              }
            : item
        ),
      };
    }

    case 'ADD_FGTS_AMOUNT': {
      const { fgtsAmount } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'fgtsAmount'
            ? {
                ...item,
                value: fgtsAmount,
              }
            : item
        ),
      };
    }

    case 'ADD_EMAIL_ADDRESS': {
      const { emailAddress } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'emailAddress'
            ? {
                ...item,
                value: emailAddress,
              }
            : item
        ),
      };
    }

    case 'ADD_PHONE_NUMBER': {
      const { phoneNumber } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'phoneNumber'
            ? {
                ...item,
                value: phoneNumber,
              }
            : item
        ),
      };
    }

    case 'ADD_FULLNAME': {
      const { fullName } = action.payload;

      return {
        ...state,
        formdata: state.formdata.map((item) =>
          item.key === 'fullName'
            ? {
                ...item,
                value: fullName,
              }
            : item
        ),
      };
    }

    case 'ADD_ENTERPRISES': {
      const { enterprisesGroups } = action.payload;
      return {
        ...state,
        enterprisesGroups: enterprisesGroups,
      };
    }

    default: {
      return state;
    }
  }
};

const store = createStore(content);

export default store;

storeSynchronize(store);

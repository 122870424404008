import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import iconBackArrow from '../../assets/icon-back.svg';

import CardEnterprise from './CardEnterprise';

const Enterprises = () => {
  const enterprisesGroups = useSelector((state) => state.enterprisesGroups);

  const history = useHistory();

  function handleClick() {
    history.push('/');
  }

  return (
    <>
      <main className="main__simulator">
        <header className="header">
          <div className="container">
            <div className="header--display">
              <span className="pagination" />
              <button type="button" onClick={handleClick}>
                <img src={iconBackArrow} alt="Voltar" />
              </button>
            </div>
          </div>
        </header>
        <div className="result">
          <p className="result--title">
            Apê na região que <br />
            você escolheu:
          </p>
        </div>
        <div className="enterprises__list">
          {enterprisesGroups.map((item) => {
            return <CardEnterprise key={item.id} data={item} />;
          })}
        </div>
      </main>
    </>
  );
};

export default Enterprises;

import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Home from '../pages/home';
import Simulator from '../pages/simulator';
import Enterprises from '../pages/enterprises';
import EnterpriseSingle from '../pages/enterprises/single';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/simulador/:step" component={Simulator} />
    <Route exact path="/imoveis" component={Enterprises} />
    <Route exact path="/imoveis/:slug" component={EnterpriseSingle} />
  </Switch>
);

export default Routes;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Infobox from '../../components/Infobox';
import iconBackArrow from '../../assets/icon-back.svg';

import imgIconUmaPessoa from '../../assets/icon-uma-pessoa.svg';
import imgIconUmaPessoaWhite from '../../assets/icon-uma-pessoa-white.svg';
import imgIconDuasPessoas from '../../assets/icon-duas-pessoas.svg';
import imgIconDuasPessoasWhite from '../../assets/icon-duas-pessoas-white.svg';
import imgIconTresPessoas from '../../assets/icon-tres-pessoas.svg';
import imgIconTresPessoasWhite from '../../assets/icon-tres-pessoas-white.svg';

const validationSchema = Yup.object({
  income: Yup.string().required('Por favor escolha uma da opções'),
});

function Step02() {
  let location;
  const history = useHistory();

  function handleClick() {
    history.push('/simulador/passo-01');
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: {
      income: '',
    },
    validationSchema,
    onSubmit() {
      history.push(location);
    },
  });

  location = {
    pathname: `/simulador/${values.income}/`,
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <main className="main__simulator">
          <header className="header">
            <div className="container">
              <div className="header--display">
                <span className="pagination">2/4</span>
                <button type="button" onClick={handleClick}>
                  <img src={iconBackArrow} alt="Voltar" />
                </button>
              </div>
            </div>
          </header>

          <div className="container">
            <div className="form__header">
              <span>2</span>
              <p>Quantas pessoas vão combinar a renda com você?</p>
            </div>
          </div>

          <div className="form-group form-control-radios">
            <input
              type="radio"
              name="income"
              id="income-one"
              value="passo-02-uma-renda"
              onChange={handleChange}
              values={values.income}
            />
            <label htmlFor="income-one" className="form-radio">
              <img className="no-checked" src={imgIconUmaPessoa} alt="" />
              <img className="is-checked" src={imgIconUmaPessoaWhite} alt="" />
              Só a minha renda
            </label>
            <input
              type="radio"
              name="income"
              value="passo-02-duas-rendas"
              id="income-two"
              onChange={handleChange}
              values={values.income}
            />
            <label htmlFor="income-two" className="form-radio">
              <img className="no-checked" src={imgIconDuasPessoas} alt="" />
              <img
                className="is-checked"
                src={imgIconDuasPessoasWhite}
                alt=""
              />
              Eu e mais uma pessoa
            </label>
            <input
              type="radio"
              name="income"
              id="income-three"
              value="passo-02-tres-rendas"
              onChange={handleChange}
              values={values.income}
            />
            <label htmlFor="income-three" className="form-radio">
              <img className="no-checked" src={imgIconTresPessoas} alt="" />
              <img
                className="is-checked"
                src={imgIconTresPessoasWhite}
                alt=""
              />
              Eu e mais 2 pessoas
            </label>
            {errors.region ? (
              <p className="form-error">{errors.region}</p>
            ) : null}
          </div>

          <Infobox
            title="Composição de renda:"
            description="Pode ser qualquer familiar, namorado(a) ou até um amigo."
          />
          {isValid && dirty && (
            <div className="button-box">
              <button className="button" type="submit">
                {isSubmitting ? 'Aguarde...' : 'Continuar'}
              </button>
            </div>
          )}
        </main>
      </form>
    </>
  );
}

export default Step02;
